/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox.entramado.net/wp-content/uploads/sites/6/lazo_control1.jpg"
  }, React.createElement(_components.img, {
    src: "http://litox.entramado.net/wp-content/uploads/sites/6/lazo_control1.jpg?w=300",
    alt: "lazo_control"
  })), "Hace algo menos de dos años tuve que decidir en la carrera si me iba por la rama de electrónica o la de automática y control, aunque yo venía de estudiar más electrónica y sobre todo informática industrial decidí entrar de lleno en el control y cada día estoy más convencido de que fue una buena elección. Sin querer dejar en mal lugar el diseño electrónico tengo que decir que cada vez le veo menos futuro, hoy en día con un Arduino y unas pocas ", React.createElement(_components.em, null, "shields"), " puedes hacer un prototipo que automatice casi cualquier tarea que sea necesaria, si necesitamos más potencia de cálculo podemos irnos a una Raspberry Pi o similares, no estoy diciendo ninguna locura, es algo que veo en mi día a día en la universidad, ya no se hace diseño electrónico, simplemente se ensamblan tarjetas y se ponen a programar. Es cierto que hacen falta unos conocimientos básicos de electrónica pero no es necesario meterse en los efectos de la mecánica cuántica en la puerta de los transistores miniaturizados -por poner un ejemplo de algo que he oído por ahí-. La conclusión de todo esto es si se está devaluando la electrónica en favor de la programación, algo que resalta el siguiente artículo de Neoteo, ", React.createElement(_components.a, {
    href: "http://www.neoteo.com/arduino-raspberry-pi-cajas-negras",
    title: "Arduino, Raspberry Pi y una historia de cajas negras."
  }, "Arduino, Raspberry Pi y una historia de cajas negras.")), "\n", React.createElement(_components.p, null, "Lo cierto es que la electrónica es cada día más una ", React.createElement(_components.em, null, "commodity"), ", algo que se produce en un país asiático y nosotros simplemente los ponemos a funcionar. Por lo tanto para aportar valor a la cadena ya no podemos dedicarnos símplemente a hacer más Arduinos o mas RaspPis, hay que aportar conocimiento y aprovecho esto para ligar esta reflexión con ", React.createElement(_components.a, {
    href: "http://lasindias.com/maquinas-o-personas/"
  }, "la de David en Las Indias"), ", la conclusión es la misma, más conocimiento aplicado, dando la importancia a las personas antes que a los procesos y las herramientas, como dice el ", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Manifiesto_%C3%A1gil"
  }, "Manifiesto ágil"), "."), "\n", React.createElement(_components.p, null, "Por todo esto es por lo que hoy estoy contento por haber tomado esa opción, estudiar control me aporta conocimientos que ninguna máquina puede replicar y cuando las máquinas consigan hacerse con estos procesos ya estaremos por delante buscando nuevos retos con la ", React.createElement(_components.a, {
    href: "http://lasindias.net/indianopedia/%C3%89tica_hacker"
  }, "ética hacker"), " mostrándonos el camino."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
